
import { object } from "yup";
import { SET_CARDS, SET_CARD_USER, SET_CARD_USERS, SET_CARD_USERS_CARDS } from "../actions/virtualCards"; 
 const initialstate={cardUsers:[],cardUsersCard:[], cards:[], cardUser:null}
export default function virtualCards(state = initialstate, action) { 
  switch (action.type) { 
    case SET_CARD_USERS_CARDS: 
      return { ...state, cardUsersCard: action.payload }; 
    case SET_CARD_USERS: 
      return { ...state, cardUsers: action.payload }; 
    case SET_CARD_USER: 
      return { ...state, cardUser: action.payload }; 
    case SET_CARDS: 
      return { ...state, cards: action.payload }; 
    default: 
      return state; 
  } 
}