import axios from "axios";
import { formattedUser } from "utils/utils";
import { HOST_URL } from "./host";
export const SET_AUTHED_USER = "SET_AUTHED_USER";
export const REMOVE_AUTHED_USER = "REMOVE_AUTHED_USER";
export const UPDATE_AUTHED_USER = "UPDATE_AUTHED_USER";
export const SET_COMPANY = "SET_COMPANY";
export const SET_AVATAR = "SET_AVATAR";
export const SET_LOGO = "SET_LOGO";
export const SET_STAMP = "SET_STAMP";
export const SET_PROFILE = "SET_PROFILE";
export const SET_USER_SERVICES = "SET_USER_PROFILE";
export const SET_USER_SETTING = "SET_USER_SETTING";
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Accept-Version": "2.0",
};
function setAuthedUser(user) {
  return {
    type: SET_AUTHED_USER,
    payload: user,
  };
}

function setAvatar(avatar) {
  return {
    type: SET_AVATAR,
    payload: avatar,
  };
}

function setLogo(logo) {
  return {
    type: SET_LOGO,
    payload: logo,
  };
}

function setStamp(stamp) {
  return {
    type: SET_STAMP,
    payload: stamp,
  };
}

export function updateAuthedUser(user) {
  return {
    type: UPDATE_AUTHED_USER,
    payload: user,
  };
}

export function removeAuthedUser() {
  return {
    type: REMOVE_AUTHED_USER,
  };
}
function setCompany(company) {
  return {
    type: SET_COMPANY,
    company,
  };
}

function setProfile(profile) {
  return {
    type: SET_PROFILE,
    profile,
  };
}

function setUserSetting(setting) {
  return {
    type: SET_USER_SETTING,
    payload: setting,
  };
}

function setUserServices(services) {
  return {
    type: SET_USER_SERVICES,
    payload: services,
  };
}

export function handleLogin({ email, password, remember }, callback) {
  const timeout = setTimeout(
    () => callback(false, "Bad Network, please try again!", false),
    50000
  );
  return async (dispatch) => {
    return axios
      .post(
        `https://admin.soleaspay.com/api/user/login/login_check`,
        { email, password },
        { withCredentials: "same-origin" }
      )
      .then((res) => {
        if (res.status === 200 && res.data.token) {
          callback(true, "You'r logged successfully");
          let user = {
            token: res.data.token,
            refreshToken: res.data.refresh_token,
            //expiredAt: new Date(`${res.data.expiredAt} UTC`)
            expiredAt: res.data.expired_at,
          };
          axios
            .get(`${HOST_URL}/api/user/get-sopay-user`, {
              headers: {
                ...headers,
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((res) => {
              //console.log(res.data)
              user = {
                ...user,
                ...formattedUser(res.data.data),
              };
              dispatch(setAuthedUser(user));
            })
            .catch((err) => {
              callback(false, "Your email, or password is invalid!", false);
              console.error(err);
            });
        } else if (
          res.status === 200 &&
          !res.data.success &&
          res.data.code === 410
        ) {
          callback(false, "Confirm with received otp code", true);
        }
      })
      .catch((err) => {
        callback(false, "Your email, or password is invalid!", false);
        console.error(err);
      })
      .finally(() => clearTimeout(timeout));
  };
}

export function handleConfirm2FaAuthed({ authCode }, callback) {
  //const timeout = setTimeout(()=>callback(false,'Bad Network, please try again!', false),25000);
  return async (dispatch) => {
    return axios
      .post(
        `https://admin.soleaspay.com/api/user/login/2fa_check`,
        { data: { authCode } },
        { withCredentials: "same-origin" }
      )
      .then((res) => {
        if (res.status === 200 && res.data.token) {
          callback(true, "You'r logged successfully");
          let user = {
            token: res.data.token,
            refreshToken: res.data.refresh_token,
            //expiredAt: new Date(`${res.data.expiredAt} UTC`)
            expiredAt: res.data.expired_at,
          };
          axios
            .get(`${HOST_URL}/api/user/get-sopay-user`, {
              headers: {
                ...headers,
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((res) => {
              user = {
                ...user,
                ...formattedUser(res.data.data),
              };
              dispatch(setAuthedUser(user));
            })
            .catch((err) => {
              callback(false, "Invalid otp Provide!", true);
              console.error(err);
            });
        } else if (res.status === 400) {
          callback(false, "Try to login again", true);
        } else {
          callback(false, res.data.message, false);
        }
      })
      .catch((err) => {
        callback(false, "Invalid or expired otp Provide!", false);
        console.error(err);
      });
    //.finally(()=>clearTimeout(timeout))
  };
}

export async function handleResend2Fa(callback) {
  //const timeout = setTimeout(()=>callback(false,'Bad Network, please try again!'),25000);

  return axios
    .get(`${HOST_URL}/api/user/login/resend-2FA-code`)
    .then((res) => {
      callback(res.data.success, res.data.message);
    })
    .catch((err) => {
      callback(false, "Something wrong, please try again later!");
      console.error(err);
    });
  // .finally(()=>clearTimeout(timeout))
}

export function handleGetAuthedUser({ token }) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/get-sopay-user`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success && res.status === 200) {
          const user = {
            ...formattedUser(res.data.data),
          };
          dispatch(updateAuthedUser(user));
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          dispatch(removeAuthedUser());
        }
        console.error(err.status);
      });
  };
}

export function handleUpdateAccountSetting(
  {
    token,
    id,
    creditAllow,
    withdrawAllow,
    transfertAllow,
    customerPaidFees,
    WebhookUrl,
    campaignLabel,
    campaignEmail
  },
  callback
) {
  return async (dispatch) => {
    return axios
      .put(
        `${HOST_URL}/api/user/config/${id}`,
        {
          credit_allow: creditAllow,
          withdraw_allow: withdrawAllow,
          transfert_allow: transfertAllow,
          customer_paid_fees: customerPaidFees,
          webhook_url: WebhookUrl,
          campaign_label: campaignLabel,
          campaign_email: campaignEmail,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            axios
              .get(`${HOST_URL}/api/user/config`, {
                headers: {
                  ...headers,
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  dispatch(setUserSetting(res.data.data));
                }
              })
              .catch((err) => console.error(err));
          } else {
            callback({ message: res.data.message, type: "danger" });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(callback({}));
  };
}

export function handleUpdateServicesSetting(
  { token, serviceId, mode },
  callback
) {
  return async (dispatch) => {
    return axios
      .get(
        `${HOST_URL}/api/user/config/services?id=${serviceId}&mode=${mode}`,
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            dispatch(setUserServices(res.data.data));
          } else {
            callback({ message: res.data.message, type: "danger" });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(callback({}));
  };
}
export async function register(
  { username, email, password, contact, countryCode, mode, referer },
  callback
) {
  const timeout = setTimeout(
    () => callback(false, "Bad Network, please try again!"),
    40000
  );

  return axios
    .post(`${HOST_URL}/api/create-user`, {
      email,
      username,
      contact,
      idd_country: countryCode,
      pass: password,
      signup_mode: mode,
      referer,
      os: navigator.platform,
      br: navigator.appCodeName,
    })
    .then((res) => {
      //console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          callback(true, res.data.message);
        } else {
          callback(false, res.data.message);
          alert(res.data.message);
        }
      }
    })
    .catch((err) => {
      console.error(err);
      callback(false, "Unknow error, please try again or contact support");
    })
    .finally(() => clearTimeout(timeout));
}

export function handleUpdateProfile(
  { token, matricule, username, email, contact },
  callback
) {
  return async (dispatch) => {
    return axios
      .put(
        `${HOST_URL}/api/user/edit-user/${matricule}`,
        {
          username,
          contact,
          email: email,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          //console.log(res.data)
          if (res.data.success) {
            const user = {
              username,
              contact,
              email,
            };
            updateAuthedUser(user);
            callback({ message: res.data.message, type: "success" });
          } else {
            callback({ message: res.data.message, type: "danger" });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(callback({}));
  };
}

export async function handleUpdatePassword(
  { token, matricule, password },
  callback
) {
  return axios
    .put(
      `${HOST_URL}/api/user/update-password/${matricule}`,
      {
        pass: password,
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        //console.log(res)
        if (res.data.success) {
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    })
    .catch((err) => console.error(err))
    .finally(callback({}));
}

export function handleEditCompany(
  {
    token,
    id,
    name,
    country,
    city,
    state,
    street,
    rccm_no,
    rccm_issue_date,
    rccm_issue_place,
    business_phone,
    business_email,
    registration
  },
  callback
) {
  return async (dispatch) => {
    return axios
      .put(
        `${HOST_URL}/api/user/edit-company/${id}`,
        {
          name,
          country,
          city,
          state,
          street,
          rccm_no,
          rccm_issue_date:
            new Date(rccm_issue_date).toISOString().split(".")[0] + "Z",
          rccm_issue_place,
          business_phone,
          business_email,
          registration
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            setCompany(res.data.data);
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(callback({}));
  };
}

export function handleEditProfile(
  {
    token,
    id,
    first_name,
    last_name,
    civility,
    marital_status,
    date_of_birth,
    country,
    city,
    state,
    street,
    profession,
    identity_doc_type,
    nui,
    id_issue_date,
    id_issue_place,
  },
  callback
) {
  return async (dispatch) => {
    return axios
      .put(
        `${HOST_URL}/api/user/edit-profile/${id}`,
        {
          first_name,
          last_name,
          civility,
          marital_status,
          date_of_birth:
            new Date(date_of_birth).toISOString().split(".")[0] + "Z",
          country,
          city,
          state,
          street,
          profession,
          identity_doc_type,
          nui,
          id_issue_date:
            new Date(id_issue_date).toISOString().split(".")[0] + "Z",
          id_issue_place,
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            setProfile(res.data.data);
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(callback({}));
  };
}

export async function handleSwitchAccount({ token }, callback) {
  return axios
    .get(`${HOST_URL}/api/user/switch-up`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          //console.log(res.data)
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    });
}

export function handleChangeEmail({ token, email }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/update-email?new=${email}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            dispatch(removeAuthedUser());
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.log(err));
  };
}

export async function handleActivateEmail({ email }, callback) {
  return axios
    .get(`${HOST_URL}/api/resend-registration-email/${email}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          callback({ type: "success", message: res.data.message });
        } else {
          callback({ type: "danger", message: res.data.message });
        }
      }
    })
    .catch((err) => console.log(err));
}

export function handleDeleteAccount({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/disable`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            dispatch(removeAuthedUser());
            alert("Please write us and let's know why you're gone.");
          } else {
            alert(res.data.message);
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleChangeAvatar({ token, file }, callback) {
  const formData = new FormData();
  formData.append("avatar", file);
  return async (dispatch) => {
    return axios
      .post(`${HOST_URL}/api/user/update-avatar`, formData, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            dispatch(setAvatar(res.data.data.avatar_path));
            callback(res.data.message);
          } else {
            callback(res.data.message);
          }
        } else {
          //console.log(res)
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleChangeLogo({ token, file }, callback) {
  const formData = new FormData();
  formData.append("logo", file);

  return async (dispatch) => {
    return axios
      .post(`${HOST_URL}/api/user/company/update-logo`, formData, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              dispatch(setLogo(res.data.data.company.logo));
              callback(res.data.message);
            } else {
              callback(res.data.message);
            }
          } else {
            //console.log(res)
          }
        })
        .catch((err) => console.error(err));
  };
}

export function handleChangeStamp({ token, file }, callback) {
  const formData = new FormData();
  formData.append("logo", file);

  return async (dispatch) => {
    return axios
      .post(`${HOST_URL}/api/user/company/update-logo?for=stamp`, formData, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              dispatch(setStamp(res.data.data.company.stamp));
              callback(res.data.message);
            } else {
              callback(res.data.message);
            }
          } else {
            //console.log(res)
          }
        })
        .catch((err) => console.error(err));
  };
}

export function handleChangeCurrency({ token, currency }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/default-currency?code=${currency}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              currency: res.data.data.devise,
              balance: parseInt(res.data.data.balance),
            };
            dispatch(updateAuthedUser(data));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleGetApiKey({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/get-apikey`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const data = {
            apiKey: res.data.data.apikey,
          };
          dispatch(updateAuthedUser(data));
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleRefreshApiKey({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/refresh-apikey`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              apiKey: res.data.data.apikey,
            };
            dispatch(updateAuthedUser(data));
          }
        }
      })
      .catch((err) => console.error(err));
  };
}
export function handleGenerateSecretKey({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/generate-secret`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              secretKey: res.data.data.secretkey,
            };
            dispatch(updateAuthedUser(data));
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleActivate2FA({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/enable-2FA`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              is2FA: true,
            };
            dispatch(updateAuthedUser(data));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export function handleDesactivate2FA({ token }, callback) {
  return async (dispatch) => {
    return axios
      .get(`${HOST_URL}/api/user/disable-2FA`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            const data = {
              is2FA: false,
            };
            dispatch(updateAuthedUser(data));
            callback({ type: "success", message: res.data.message });
          } else {
            callback({ type: "danger", message: res.data.message });
          }
        }
      })
      .catch((err) => console.error(err));
  };
}

export async function handleForgottenPassword({ email }, callback) {
  const timeout = setTimeout(
    () => callback(false, "Bad Network, please try again!"),
    50000
  );

  return axios
    .get(`${HOST_URL}/api/forgotten-password?email=${email}`)
    .then((res) => {
      //console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          callback(true, res.data.message);
        } else {
          callback(false, res.data.message);
        }
      }
    })
    .catch((err) => {
      console.error(err);
      callback(false, "Unknow error, please try again or contact support");
    })
    .finally(() => clearTimeout(timeout));
}

export async function handleResetPassword({ token, password }, callback) {
  const timeout = setTimeout(
    () => callback(false, "Bad Network, please try again!"),
    50000
  );

  return axios
    .get(`${HOST_URL}/api/reset-password?token=${token}&password=${password}`)
    .then((res) => {
      if (res.status === 200) {
        //console.log(res)
        if (res.data.success) {
          callback(true, res.data.message);
        } else {
          callback(false, res.data.message);
        }
      }
    })
    .catch((err) => {
      console.error(err);
      callback(false, "Unknow error, please try again or contact support");
    })
    .finally(() => clearTimeout(timeout));
}
